var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ielts-spoken"},[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"banner-content"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title"},[_vm._v("雅思口语素材练习系统")]),_vm._m(0),_c('div',{staticClass:"action"},[_c('div',{staticClass:"button",on:{"click":_vm.buyCourse}},[_vm._v("立即购买")]),_vm._m(1)])]),_vm._m(2)])]),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subtitle"},[_vm._v(" 题库+范文+音频+视频课 P2更提供10种题材选择"),_c('br'),_vm._v(" 完整题库|专业团队|题材新颖|智能备考 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qr-code-spoken"},[_c('img',{attrs:{"src":require("./img/qrcode.png"),"alt":""}}),_c('div',{staticClass:"desc"},[_vm._v(" 扫码下载趴趴英语app"),_c('br'),_vm._v(" 开启练习 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("./img/phone.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('img',{attrs:{"src":require("./img/material_pic1.png"),"alt":""}}),_c('img',{attrs:{"src":require("./img/material_pic2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase"},[_c('div',{staticClass:"title"},[_vm._v("购买流程")]),_c('div',{staticClass:"title-en"},[_vm._v("Purchase process")]),_c('div',{staticClass:"desc-img"},[_c('img',{attrs:{"src":require("./img/material_pic3.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"usage"},[_c('div',{staticClass:"title"},[_vm._v("使用流程")]),_c('div',{staticClass:"title-en"},[_vm._v("STEP FOR USAGE")]),_c('div',{staticClass:"part"},[_c('div',{staticClass:"title"},[_vm._v("Part1&Part3")]),_c('div',{staticClass:"subtitle"},[_vm._v("P1&P3购买后无需选题可直接学习，趴趴建议您可以先学习趴趴明星老师Sharon的网课后（网课在每个完整答案左下角），再理解性的记忆")]),_c('div',{staticClass:"desc-image"},[_c('img',{attrs:{"src":require("./img/material_pic4.png"),"alt":""}})])]),_c('div',{staticClass:"part"},[_c('div',{staticClass:"title"},[_vm._v("Part2")]),_c('div',{staticClass:"desc"},[_c('img',{attrs:{"src":require("./img/material_pic5.png"),"alt":""}}),_c('div',{staticClass:"step"},[_c('div',{staticClass:"one"},[_vm._v(" 1 “选题材”"),_c('br'),_vm._v(" 根据中文内容和难度来选择适合自己的题材 ")]),_c('div',{staticClass:"two"},[_vm._v(" 2 “生成英文素材”"),_c('br'),_vm._v(" 点击左下角“保存并练习” ")])]),_c('img',{attrs:{"src":require("./img/material_pic6.png"),"alt":""}}),_c('div',{staticClass:"step"},[_c('div',{staticClass:"three"},[_vm._v(" 3 “开始练习”"),_c('br'),_vm._v(" 点击左下角可听外教录音 ")]),_c('div',{staticClass:"four"},[_vm._v(" 4 “生成PDF"),_c('br'),_vm._v(" 选完所有Part2的答案并保存，点击“生成PDF” ")]),_c('div',{staticClass:"five"},[_vm._v(" 5 “下载PDF” 点击“生成"),_c('br'),_vm._v("PDF”，可选择复制地址链接到任何浏览器上直接下载PDF ")])]),_c('img',{attrs:{"src":require("./img/material_pic7.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }