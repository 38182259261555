<template>
  <div class="ielts-spoken">
    <div class="banner">
      <div class="banner-content">
        <div class="left">
          <div class="title">雅思口语素材练习系统</div>
          <div class="subtitle">
            题库+范文+音频+视频课 P2更提供10种题材选择<br>
            完整题库|专业团队|题材新颖|智能备考
          </div>
          <div class="action">
            <div class="button" @click="buyCourse">立即购买</div>
            <div class="qr-code-spoken">
              <img src="./img/qrcode.png" alt="">
              <div class="desc">
                扫码下载趴趴英语app<br>
                开启练习
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <img src="./img/phone.png" alt="">
        </div>
      </div>
    </div>
    <div class="desc">
      <img src="./img/material_pic1.png" alt="">
      <img src="./img/material_pic2.png" alt="">
    </div>
    <div class="purchase">
      <div class="title">购买流程</div>
      <div class="title-en">Purchase process</div>
      <div class="desc-img"><img src="./img/material_pic3.png" alt=""></div>
    </div>
    <div class="usage">
      <div class="title">使用流程</div>
      <div class="title-en">STEP FOR USAGE</div>
      <div class="part">
        <div class="title">Part1&Part3</div>
        <div class="subtitle">P1&P3购买后无需选题可直接学习，趴趴建议您可以先学习趴趴明星老师Sharon的网课后（网课在每个完整答案左下角），再理解性的记忆</div>
        <div class="desc-image">
          <img src="./img/material_pic4.png" alt="">
        </div>
      </div>
      <div class="part">
        <div class="title">Part2</div>
        <div class="desc">
          <img src="./img/material_pic5.png" alt="">
          <div class="step">
            <div class="one">

              1 “选题材”<br>
              根据中文内容和难度来选择适合自己的题材

            </div>
            <div class="two">
              2 “生成英文素材”<br>
              点击左下角“保存并练习”
            </div>
          </div>
          <img src="./img/material_pic6.png" alt="">
          <div class="step">

            <div class="three">
              3 “开始练习”<br>
              点击左下角可听外教录音
            </div>
            <div class="four">

              4 “生成PDF<br>
              选完所有Part2的答案并保存，点击“生成PDF”

            </div>
            <div class="five">
              5 “下载PDF” 点击“生成<br>PDF”，可选择复制地址链接到任何浏览器上直接下载PDF
            </div>

          </div>
          <img src="./img/material_pic7.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Spoken',
    methods:{
      buyCourse () {
        window.open('https://detail.tmall.com/item.htm?spm=a230r.1.14.6.5b3745d9SGWrLl&id=591532625100&cm_id=140105335569ed55e27b&abbucket=5&sku_properties=14829532:4378929205')
      }
    }
  }
</script>

<style lang="less">
  .ielts-spoken {
    & > .banner {
      width: 100%;
      height: 400px;
      background: #292C31;
      color: #fff;

      & > .banner-content {
        width: 950px;
        height: 100%;
        align-items: center;
        display: flex;
        margin: 0 auto;

        & > .left {
          width: 400px;
          text-align: left;
          margin-right: 170px;

          & > .title {
            font-size: 22px;
            font-weight: bold;
          }

          & > .subtitle {
            font-size: 16px;
            margin: 32px 0 48px;
            line-height: 32px;
          }

          & > .action {
            display: flex;
            align-items: center;

            & > .button {
              width: 88px;
              height: 32px;
              background: #29D087;
              border-radius: 3px;
              text-align: center;
              line-height: 32px;
              font-size: 12px;
              margin-right: 61px;
              cursor: pointer;
            }

            & > .qr-code-spoken {
              display: flex;
              align-items: center;

              & > img {
                width: 67px;
                height: 67px;
                margin-right: 21px;
              }

              & > .desc {
                line-height: 28px;

              }
            }

          }
        }

        & > .right {

        }
      }

    }

    & > .desc {
      padding: 70px 0 90px;
      background: #fff;
    }

    & > .purchase {
      background: #F8FAFC;
      width: 100%;
      height: 540px;
      text-align: center;
      padding-top: 55px;

      & > .title {
        margin: 0 0 12px;
        font-size: 30px;
        color: #333;
        font-weight: bold;
      }

      & > .title-en {
        color: #999;
        font-size: 16px;
        margin-bottom: 40px;
      }
    }

    & > .usage {
      background: #fff;
      padding: 80px 0;

      & > .title {
        font-size: 30px;
        color: #333;
        font-weight: bold;
      }

      & > .title-en {
        color: #999;
        font-size: 16px;
        margin: 12px 0 35px;
      }

      & > .part {
        & > .title {
          font-size: 24px;
          color: #555;
          font-weight: bold;
        }

        & > .subtitle {
          color: #555;
          font-size: 16px;
          margin: 5px 0 25px;
        }

        & > .desc {
          margin: 25px auto;
          width: 1200px;
          display: flex;
          align-items: center;
          &>.step{
            width: 170px;
            height: 325px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: 0 60px;
            font-size: 16px;
            color: #555;
            text-align: left;
            line-height: 30px;
          }
        }
      }
    }
  }
</style>
